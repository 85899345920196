import { Box, Link } from '@amzn/awsui-components-react';
import React from 'react';

export const PackageInstructions = () => (
  <Box variant={'p'}>
    Add the documentation package information for this launch as you would for{' '}
    <Link href={'https://build.amazon.com'}>build.amazon.com</Link>, including
    any package dependencies (service-level checklist packages, service-level
    shared content, etc. Do not add "global" packages like AWSSharedContent).
    TLC builds your docs and provides a link to the build status. When the build
    is done, you can preview the output or update the package info to build and
    preview a different commit. When your docs are ready for launch, choose
    Ready to Publish.
  </Box>
);
